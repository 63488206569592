var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"bordered":false}},[_c('a-form',{staticStyle:{"width":"60%","margin":"0 auto"},attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":10,"offset":5}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]),expression:"['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]),expression:"['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"位置"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['location', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]),expression:"['location', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"面积(平方米)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['area', {initialValue:0, rules: [{required: false, message: '必填项，请填写信息'}]}]),expression:"['area', {initialValue:0, rules: [{required: false, message: '必填项，请填写信息'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'category',
              {
                rules: [
                  { required: true, message: '请选择' },
                ],
              },
            ]),expression:"[\n              'category',\n              {\n                rules: [\n                  { required: true, message: '请选择' },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"选择"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 摊位 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 超市 ")])],1)],1)],1)],1),_c('a-form-item',_vm._b({staticStyle:{"width":"60%","margin":"0 auto"}},'a-form-item',_vm.buttonCol,false),[_c('a-row',[_c('a-col',{attrs:{"span":"6"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1),_c('a-col',{attrs:{"span":"2"}}),_c('a-col',{attrs:{"span":"10"}},[_c('a-button',{on:{"click":_vm.handleGoBack}},[_vm._v("返回")])],1),_c('a-col',{attrs:{"span":"4"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }